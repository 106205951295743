import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  environment
} from '@/utils'
import {
  cancelPackageUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import HjexTimelineV2 from '@/views/shared-components/timeline/HjexTimelineV2.vue'

export default {
  name: 'ModalChangeCourier',
  components: {
    HjexTimelineV2
  },
  props: {
    isShowDialog: {
      default: () => false
    },
    idPackage: {
      default: () => null
    },
    type: {
      default: () => 0
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          // eslint-disable-next-line no-use-before-define
          hideDialog()
        }
      }
    })
    const dataSource = ref(null)
    const isLoading = ref(true)

    const hideDialog = () => {
      emit('hideDialog')
    }

    const getPackgeData = async () => {
      const response = await cancelPackageUseCase.getCancelPackageLogs(propertiesProps.idPackage)
      if (!response.error) {
        dataSource.value = response.result.reverse()
        isLoading.value = false
      } else {
        console.log(response.error)
      }
    }

    onMounted(() => {
      console.log('selectedId', propertiesProps.idPackage)
      getPackgeData()
    })

    return {
      isShowModal,
      hideDialog,
      dataSource,
      isLoading
    }
  }
}
