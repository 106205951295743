
import { ref, reactive, onMounted } from 'vue'
import moment from 'moment'

export default {
  name: 'HjexTimelineV2',
  props: {
    datas: {
      default: () => null
    },
    datax: {
      default: () => []
    },
    biayaTambahan: {
      default: () => null
    }
  },
  setup(props: any) {
    // const propertiesProps = reactive(props)
    // const { data, datax } = propertiesProps
    const datas = reactive(props.datas)
    const datax = ref(props.datax)

    onMounted(() => {
      console.log('datas', datas)
    })
    return {
      // eslint-disable-next-line vue/no-dupe-keys
      datas,
      // eslint-disable-next-line vue/no-dupe-keys
      datax,
      moment
    }
  }
}
