import {
  ref, watch, onMounted, getCurrentInstance, reactive
} from 'vue'
import ModalConfirmCancelPackage from '@/views/transactions/delivery-item/delivery-canceled/components/ModalPackageCancelation.vue'
import ModalPackageCancelationLog from '@/views/transactions/delivery-item/delivery-canceled/components/ModalPackageCancelationLog.vue'
import { cancelPackageUseCase } from '@/domain/usecase'
import { upperCase } from 'lodash'

export default {
  name: 'DeliveryCanceled',
  components: {
    ModalConfirmCancelPackage,
    ModalPackageCancelationLog
  },
  setup() {
    const isShowDialogConfirmCancelPackage = ref(false)
    const isShowDialogConfirmCancelPackageLog = ref(false)
    const requestedConfirmCancelPackage = ref(null)
    const dataSource = ref([]) as any
    const inputSearch = ref(null)
    const dialogType = ref(0)
    const params = {
      custom: 'StatusPengirimanCodes=agb,hgs',
      search: ''
    }

    const hideModalConfirmCancelPackage = () => {
      console.log('hiding modal')
      isShowDialogConfirmCancelPackage.value = false
    }

    const hideModalConfirmCancelPackageLog = () => {
      console.log('hiding modal')
      isShowDialogConfirmCancelPackageLog.value = false
    }

    const getAllData = async (search: any = '') => {
      inputSearch.value = search
      if (inputSearch.value) {
        params.search = `Resi=${search}`
      }
      const response = await cancelPackageUseCase.getAll(params)
      if (!response.error) {
        dataSource.value = response.result.Data
        // dataSource.value.StatusKode = upperCase(dataSource.value.StatusPengirimanKode)
      } else {
        console.log(response.error)
      }
    }

    const reloadData = () => {
      console.log('loading')
      inputSearch.value = null
      params.search = ''
      getAllData()
    }

    const showDetail = (id: any) => {
      console.log('show detail id', id)
      requestedConfirmCancelPackage.value = id
      isShowDialogConfirmCancelPackageLog.value = true
    }

    const showDialog = (id: any, type: any) => {
      console.log('show dialog', [id, type])
      requestedConfirmCancelPackage.value = id
      dialogType.value = type
      isShowDialogConfirmCancelPackage.value = true
    }

    const searchData = (val: any) => {
      getAllData(val)
    }

    onMounted(() => {
      getAllData()
    })

    return {
      isShowDialogConfirmCancelPackage,
      requestedConfirmCancelPackage,
      hideModalConfirmCancelPackage,
      reloadData,
      dataSource,
      showDetail,
      showDialog,
      dialogType,
      searchData,
      inputSearch,
      isShowDialogConfirmCancelPackageLog,
      hideModalConfirmCancelPackageLog
    }
  }
}
