import {
  ref,
  getCurrentInstance,
  reactive,
  computed,
  onMounted
} from 'vue'
import {
  environment
} from '@/utils'
import {
  cancelPackageUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'

export default {
  name: 'ModalChangeCourier',
  props: {
    isShowDialog: {
      default: () => false
    },
    idPackage: {
      default: () => null
    },
    type: {
      default: () => 0
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const isShowModal = computed({
      get: () => propertiesProps.isShowDialog,
      set: (val) => {
        if (!val) {
          // eslint-disable-next-line no-use-before-define
          hideDialog()
        }
      }
    })
    const dataSource = ref(null)
    const submitType = propertiesProps.type

    const hideDialog = () => {
      emit('hideDialog')
    }

    const responseResult = (res: any) => {
      if (!res.error) {
        $toast.add({
          severity: 'success',
          detail: res.result.detail ?? res.result.Detail,
          group: 'bc',
          life: 2000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: res.message,
          group: 'bc',
          life: 2000
        })
      }
    }

    const getPackgeData = async () => {
      const response = await cancelPackageUseCase.getOneAcknowledgeCancelPackage(propertiesProps.idPackage)
      if (!response.error) {
        dataSource.value = response.result
        console.log('dataSource', dataSource)
      } else {
        console.log(response.error)
      }
    }

    const submit = async () => {
      let response = null
      if (submitType === 0) {
        response = await cancelPackageUseCase.declineCancelPackage(propertiesProps.idPackage)
      } else {
        response = await cancelPackageUseCase.approveCancelPackage(propertiesProps.idPackage)
      }
      responseResult(response)
    }

    onMounted(() => {
      console.log('selectedId', propertiesProps.idPackage)
      console.log('type', submitType)
      getPackgeData()
    })

    return {
      isShowModal,
      hideDialog,
      dataSource,
      submitType,
      submit
    }
  }
}
